<template>
    <a-modal width="80%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose">
      <a-divider orientation="left">
      </a-divider>
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="购买类型" prop="type">-->
<!--&lt;!&ndash;                <a-input v-model="queryParam.type" placeholder="请输入是否可以使用金币购买 0-否 1-是" allow-clear/>&ndash;&gt;-->
<!--                <a-select v-model="queryParam.type">-->
<!--                  <a-select-option :key="0">免费</a-select-option>-->
<!--                  <a-select-option :key="1">金币</a-select-option>-->
<!--                  <a-select-option :key="2">钻石</a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->

<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="短剧名称" prop="videoId">-->
<!--&lt;!&ndash;                <a-input v-model="queryParam.videoId" placeholder="请输入短剧id" allow-clear/>&ndash;&gt;-->
<!--                <a-tree-select-->
<!--                  v-model="queryParam.videoId"-->
<!--                  show-search-->
<!--                  style="width: 100%"-->
<!--                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"-->
<!--                  placeholder="请选择短剧"-->
<!--                  allow-clear-->
<!--                  tree-default-expand-all-->
<!--                  showSearch-->
<!--                  treeNodeFilterProp="title"-->
<!--                >-->
<!--                  <a-tree-select-node :value="item.id" :key="item.id" :title="item.videoName" v-for="(item,index) in listAll">-->
<!--                  </a-tree-select-node>-->
<!--                </a-tree-select>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="集数" prop="serialNumber">-->
<!--                <a-input v-model="queryParam.serialNumber" placeholder="请输入集数" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :md="8" :sm="24">-->
<!--              <a-form-item label="短剧时长" prop="duration">-->
<!--                <a-input v-model="queryParam.duration" placeholder="请输入短剧时长" allow-clear/>-->
<!--              </a-form-item>-->
<!--            </a-col>-->
            <template v-if="advanced">
<!--              <a-col :md="8" :sm="24">-->
<!--                <a-form-item label="短剧url" prop="videoUrl">-->
<!--                  <a-input v-model="queryParam.videoUrl" placeholder="请输入短剧url" allow-clear/>-->
<!--                </a-form-item>-->
<!--              </a-col>-->


            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
<!--                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>-->
<!--                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>-->
<!--                <a @click="toggleAdvanced" style="margin-left: 8px">-->
<!--                  {{ advanced ? '收起' : '展开' }}-->
<!--                  <a-icon :type="advanced ? 'up' : 'down'"/>-->
<!--                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
<!--        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['video:gather:add']">-->
<!--          <a-icon type="plus" />新增-->
<!--        </a-button>-->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['video:gather:edit']">
          <a-icon type="edit" />修改
        </a-button>
<!--        <a-button type="primary"  @click="$refs.UploadFileeForm.handleAdd()" v-hasPermi="['video:gather:add']">-->
<!--          <a-icon type="upload" />文件上传-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['video:gather:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="dashed" @click="$refs.importExcel.importExcelHandleOpen(id)"
                >
          <a-icon type="import" />导入
        </a-button>
<!--        <a-button type="primary" @click="handleExport">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['video:gather:export']">-->
<!--          <a-icon type="download" />导出-->
<!--        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>

<!--        <div slot="videoUrl" slot-scope="text, record">-->
<!--          <video :src="record.videoUrl" width="300" height="150" controls></video>-->
<!--        </div>-->

        <span slot="videoUrl" slot-scope="text, record">
          <a-button type="primary" block @click="$refs.VideoUtil.getUrl(record.videoUrl)">预览
<!--             <video :src="record.videoUrl" width="300" height="150" controls></video>-->
          </a-button>
        </span>


        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['video:gather:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['video:gather:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['video:gather:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['video:gather:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <UploadFileeForm ref="UploadFileeForm" />
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <video-util ref="VideoUtil"></video-util>
    </a-modal>

</template>

<script>
import { pageGather,listGather, delGather } from '@/api/video/gather'
import CreateForm from './modules/CreateForm'
import VideoUtil from './modules/VideoUtil'
import UploadFileeForm from './modules/UploadFileeForm'
import { tableMixin } from '@/store/table-mixin'
import { listVideo } from '@/api/video/video'
import ImportExcel from './modules/ImportExcel'

export default {
  name: 'Gather',
  components: {
    CreateForm,
    VideoUtil,
    UploadFileeForm,
    ImportExcel
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      open: false,
      selectedRowKeys: [],
      id: "",
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      listAll:[],
      // 查询参数
      queryParam: {
        isGoldBuy: null,
        duration: null,
        videoUrl: null,
        videoId: null,
        serialNumber: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   align: 'center'
        // },
        // {
        //   title: '购买类型',
        //   dataIndex: 'type',
        //   align: 'center',
        //   customRender: function (t, r, index) {
        //     if(t == 0) {
        //       return '免费'
        //     }else if(t == 1) {
        //       return '金币'
        //     }else if(t == 2) {
        //       return '钻石'
        //     }
        //   }
        // },
        // {
        //   title: '短剧时长',
        //   dataIndex: 'duration',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '短剧名称',
          dataIndex: 'videoName',
          align: 'center'
        },
        // {
        //   title: '集数',
        //   dataIndex: 'serialNumber',
        //   align: 'center'
        // },
        {
          title: '视频',
          dataIndex: 'videoUrl',
          align: 'center',
          scopedSlots: { customRender: 'videoUrl' },
        },


        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {


    getGather() {
      listVideo().then(res => {
        this.listAll = res.data;
      })
    },
    /** 查询剧集列表 */
    getList () {
      this.loading = true
      this.queryParam.videoId=this.id
     pageGather(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onClose () {
      this.open = false
      this.id=""
      this.uploadLoading = false
    },
    getLists (value) {
      this.id = value
      this.queryParam.videoId=value
      this.open=true
      pageGather(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        isGoldBuy: undefined,
        duration: undefined,
        videoUrl: undefined,
        videoId: undefined,
        serialNumber: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delGather(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('video/video-gather/export', {
            ...that.queryParam
          }, `剧集_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
